import React from 'react';

const Termly = ({ dataid }) => {
  // OK, THIS IS EPIC
  React.useEffect(() => {
    const f = async () => {
      var content = await fetch('/termly.html.x');
      content = await content.text();
      var iframe = document.getElementById('termlyiframe');

      // replace data-id
      content = content.replace(`data-id=""`, `data-id="${dataid}"`);

      var frameDoc = iframe.document;
      if (iframe.contentWindow) frameDoc = iframe.contentWindow.document;

      frameDoc.open();
      frameDoc.writeln(content);
      frameDoc.close();
    };
    f();
  }, [dataid]);
  return (
    <iframe
      title="terms and conditions"
      frameBorder="0"
      style={{ height: '100vh', width: '100%' }}
      id="termlyiframe"
      src="about:blank"></iframe>
  );
};

export default Termly;
