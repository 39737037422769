import React from 'react';

import Termly from 'src/components/Termly';
import Layout from 'src/layout/Layout';
import AnalyticsScript from 'src/components/Analytics';

const Return = () => {
  return (
    <Layout>
      <Termly dataid="19307ccb-5352-48e7-bb07-c8a3b5564786" />
      <AnalyticsScript />
    </Layout>
  );
};

export default Return;
